
















































import Vue from "vue";
import { Getter } from "vuex-class";
import { Component } from "vue-property-decorator";

import DarkModeSwitch from "./components/DarkModeSwitch.vue";
import BaselineSessions from "./components/BaselineSessions.vue";
import BaselineAnalysis from "@/core/models/sessions/BaselineAnalysis";
import BaselineSessionUpload from "./components/BaselineSessionUpload.vue";

@Component({
  components: {
    DarkModeSwitch,
    BaselineSessions,
    BaselineSessionUpload,
  },
})
export default class Settings extends Vue {
  @Getter("profile/baseline") baseline!: BaselineAnalysis;
  @Getter("profile/permissions") permissions?: string[];
  get hasTrainingPermissions() {
    return this.permissions?.includes("session_training");
  }
}
